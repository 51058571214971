import moment from 'moment';
import {
  getTrackById,
  getAlbumById,
  getFilmById,
  getVideoById,
  getVideoByContentfulId,
  getMoreById,
  getPlaylistById,
} from './drawer-items';
import {
  verifyFreePassTokenExpiration,
  freePassToken,
  setFreePassToken,
  hasAuth,
  setSubInfo,
} from './services/api';
import store from './store';
import { setuserData } from './actions/user-information';
import {
  ACTIVE,
  CANCELLED,
  PATRON,
  RUSTV2,
  UNLIMITEDV2,
  RUST,
  UNLIMITED,
} from './util/contants/url_constants';

/*

   A: free user
        access level: free           // access control level of this user
        can cancel: false            // whether this plan can be cancelled
        has subscription info: false // has past receipts

   B: paid user  'active'
        access level: paid
        can cancel: true
        has subsciption info: true

   C: declined user (still active)
        access level: free
        can cancel: true
        has subsciption info: true

   D: cancelled user (still active)
        access level: paid
        can cancel: false
        has subsciption info: true

   E: declined user (inactive)
        access level: free
        can cancel: false
        has subsciption info: true

   F: cancelled user (inactive)
        access level: free
        can cancel: false
        has subsciption info: true
*/

//below vars determine access control etc. they will be updated from the server appropriately
//whether the user is restricted from viewing some content
//FOR RELEASE: should be set to true
let userFreePassExpired = true;
export const setFreePassExpiration = (valid) => {
  userFreePassExpired = valid;
};
//whether the user has (or has had) an active subscription
//FOR RELEASE: should be set to false
export const getUserSubscriptionInfo = () => {
  return store.getState().userData;
};
export const userHasSubscription = () => {
  const { userIsFree = true } = getUserSubscriptionInfo();
  return hasAuth() && !userIsFree;
};

//status can be 'active', 'cancelled-active', 'declined-active', 'cancelled-inactive', 'declined-inactive'
//only relevant when userHasSubscriptionInfo === true
// let userSubscriptionStatus = 'active'
let billingInfo = {
  last4: '0000',
  card: 'Visa',
  expirationDateText: '00/0000',
};

// ignore free pass and unlimited access
export function requiredSubscription() {
  const hasSubscription = userHasSubscription();
  let requiresSubscription = unlimitedAccess() ? false : true;

  if (hasAuth() && hasSubscription) {
    requiresSubscription = false;
  }
  return requiresSubscription;
}
export function userIsNotSubscribed() {
  const { userHasSubscriptionInfo } = getUserSubscriptionInfo();
  return !userHasSubscriptionInfo;
}

export function userPlan() {
  const { userPlanId } = getUserSubscriptionInfo();
  return userPlanId;
}

export function userSubscriptionType() {
  const { userSubType } = getUserSubscriptionInfo();
  return userSubType;
}

export function determineUserStatusFromSubscriptionResponse(json) {
  const {
    planId,
    active,
    status,
    type,
    current_period_end = false,
    stripe_status,
    isAppleSub = false,
    userName,
    proration_credit,
    cancel_at_period_end = false,
  } = json;

  let user = {};
  user.hasPlanExpired = true;
  user.userPlanId = planId;
  user.userPricingId = planId;
  user.userSubType = type;
  user.isAppleSub = isAppleSub;
  user.userName = userName || '';
  user.proration_credit = proration_credit;
  user.cancelSubscriptionAtPeriodEnd = cancel_at_period_end;

  if (current_period_end) {
    let now = moment();
    let expirationDate = new Date(current_period_end * 1000);
    user.hasPlanExpired = now.isAfter(moment(+expirationDate));
  }

  if (user.hasPlanExpired && stripe_status !== 'active') {
    user.userIsFree = true; //free
    user.userHasSubscriptionInfo = false;
    user.userSubscriptionStatus = null;

    setuserData(user);
    return;
  }

  user.userHasSubscriptionInfo = true;

  if (cancel_at_period_end) {
    user.subscriptionEndDate = moment(cancel_at_period_end * 1000).calendar();
  }

  if (status === ACTIVE) {
    user.userSubscriptionStatus = 'active';
    user.isGift = json.gift || false;
    user.customerCoupon = json.customerCoupon || '';
    user.userIsFree = false; //paid
    user.relevantSubscriptionDate = json.dateSubscriptionWillRenew;
    if (cancel_at_period_end){
      user.relevantSubscriptionDate = json.dateSubscriptionWillEnd
    }
    user.isSubscriptionTrial = json?.stripe_status === 'trialing' || false;
    user.lifetime = json?.lifetime || false;
  } else if (status === CANCELLED) {
    if (active) {
      user.userSubscriptionStatus = 'cancelled-active';
      user.userIsFree = false; //paid
      user.relevantSubscriptionDate = json.dateSubscriptionWillEnd;
    } else {
      user.userSubscriptionStatus = 'cancelled-inactive';
      user.userIsFree = true; //free
      user.relevantSubscriptionDate = json.dateSubscriptionEnded;
    }
  } else {
    //DECLINED
    if (active) {
      user.userSubscriptionStatus = 'declined-active';
      user.userIsFree = true; //free
      user.relevantSubscriptionDate = json.current_period_end;
    } else {
      user.userSubscriptionStatus = 'declined-inactive';
      user.userIsFree = true; //free
      user.relevantSubscriptionDate = json.dateSubscriptionEnded;
    }
  }
  user.relevantSubscriptionDate = moment(
    user.relevantSubscriptionDate * 1000
  ).calendar();

  setuserData(user);
  return user;
}

export function infoCardIsLockedByID(id) {
  const { userIsFree = true } = getUserSubscriptionInfo();
  if (unlimitedAccess() || !userIsFree) return false;
  const track = getTrackById(id);
  if (!track) return true;
  return !track.free;
}

export function photoCollectionIsLockedByTrackID(id) {
  return infoCardIsLockedByID(id);
}

export function albumIsLockedByID(id) {
  const { userIsFree = true } = getUserSubscriptionInfo();
  if (!userIsFree) return false;
  const album = getAlbumById(id);
  if (!album) return true;
  return !album.free;
}

export function filmIsLockedByID(id) {
  const { userIsFree = true } = getUserSubscriptionInfo();
  if (!userIsFree) return false;
  const film = getFilmById(id);
  if (!film) return true;
  return !film.free;
}

export function videoIsLockedByID(id) {
  if (id === 'qIuQOVBqRq6S2iMAg68oi') return false;
  if (!isFreeUser()) return false;
  const video = getVideoById(id);
  if (!video) return true;
  return !video.free;
}
export function videoIsLockedByContentfulID(id) {
  if (id === 'qIuQOVBqRq6S2iMAg68oi') return false;
  if (!isFreeUser()) return false;
  const video = getVideoByContentfulId(id);
  if (!video) return true;
  return !video.free;
}

export function moreVideoIsLockedByID(id) {
  if (!isFreeUser()) return false;
  const more = getMoreById(id);
  if (!more) return true;
  return !more.free;
}

export async function getTokenStatus() {
  await verifyFreePassTokenExpiration()
    .then((res) => {
      res.time = new Date();
      setFreePassToken(res);
      if (!res.validity) location.reload();
      return res;
    })
    .catch(() => {
      setFreePassToken({ content: '', validity: false });
      location.reload();
      return true;
    });
}
function diffMinutes(token) {
  let currentDate = new Date();
  let minutes = 5;
  if (token !== null && token.time) {
    let timeDiff = Math.abs(new Date(token.time) - currentDate) / 1000;
    minutes = Math.floor(timeDiff / 60) % 60;
    timeDiff -= minutes * 60;
  }

  return minutes >= 5;
}

export function handleTokenExpiration() {
  let token = freePassToken();

  if (token !== null && token.validity) {
    if (userHasSubscription()) {
      setFreePassToken({ content: '', validity: false });
      setFreePassExpiration(true);
      location.reload();
    } else {
      let cachedTime = diffMinutes(token);
      if (cachedTime) getTokenStatus();
    }
  }
}

export function unlimitedAccess() {
  return store.getState().access.unlimitedAccess;
}

export function isFreeUser() {
  let { userIsFree = true } = getUserSubscriptionInfo();
  handleTokenExpiration();
  let hasUnlimitedAccess = unlimitedAccess() ? false : userFreePassExpired;
  let token = freePassToken();
  userFreePassExpired = token !== null && token.validity ? false : true;

  if (hasAuth()) {
    userIsFree = userIsFree ? userFreePassExpired : userIsFree;
    if (unlimitedAccess()) userIsFree = hasUnlimitedAccess;
  } else {
    userIsFree = unlimitedAccess() ? false : userFreePassExpired;
  }
  return userIsFree;
}

export function setUserLastPaymentMethod(info) {
  const { last4, brand, exp_month, exp_year } = info || {};
  if (!(last4 && brand)) return;
  billingInfo = {
    last4,
    card: brand,
    expirationDateText: `${exp_month}/${exp_year}`,
  };
}

export function userLastPaymentMethod() {
  return billingInfo || {};
}

export function userCanCancelSubscription() {
  const { userIsFree, userSubscriptionStatus } = getUserSubscriptionInfo();
  return !userIsFree && userSubscriptionStatus !== 'cancelled-active';
}
export function hasSubscriptionInfo() {
  const { userHasSubscriptionInfo } = getUserSubscriptionInfo();
  return userHasSubscriptionInfo;
}

//subscription state that user can access their subscription
function subscriptionState(state) {
  let table = {
    active: true,
    'declined-active': true,
    'cancelled-active': true,
  };
  return table[state];
}

//whether the 'change payment method' button should be shown
export function canChangePaymentMethod() {
  const { userHasSubscriptionInfo, userSubscriptionStatus: state } =
    getUserSubscriptionInfo();
  if (!userHasSubscriptionInfo) return false;

  return subscriptionState(state);
}

// whether the user can purchase a new subscription
export function userCanPurchaseNewSubscription() {
  if (!userHasSubscriptionInfo) return true;
  const state = userSubscriptionStatus;
  return state === 'cancelled-inactive' || state === 'declined-inactive';
}

export function isUserAuthenticated() {
  let isAuth = false;
  if (hasAuth()) isAuth = true;
  return isAuth;
}
export function hasClassic() {
  const { hasPlanExpired = true, userPlanId = false } =
    getUserSubscriptionInfo();
  if (!userPlanId) return false;
  return (
    !hasPlanExpired &&
    !(userPlanId.includes(PATRON) || userPlanId.includes(RUSTV2)|| userPlanId.includes(RUST))
  );
}

export function hasPatron() {
  const { hasPlanExpired = true, userPlanId = false } =
    getUserSubscriptionInfo();
  if (!userPlanId) return false;
  return !hasPlanExpired && userPlanId.includes(PATRON);
}

export function isAppleSubscription() {
  const { isAppleSub } = getUserSubscriptionInfo();
  return isAppleSub;
}

export function canViewExclusiveContent() {
  const { hasPlanExpired = true, userPlanId = false } =
    getUserSubscriptionInfo();
  if (!userPlanId) return false;
  return (
    !hasPlanExpired && (userPlanId.includes("PATRON") || userPlanId.includes("RUSTV2") || userPlanId.includes("RUST"))
  );
}

export const hasNotUnlimitedAccess = (excludeFreeAndClassicSubscription = false) => {
  // Validate if the album/track is exclusive to PATRON and RUST subs
  return excludeFreeAndClassicSubscription && !canViewExclusiveContent();
}
