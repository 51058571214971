import React, {Component} from 'react'
import SectionPage        from './components/section-page'
import store              from './store'
import marked             from 'marked'
import headerImg from '../images/welcome/header.webp'

export default class WelcomeContainer extends Component {
    getContent(){
        return store.getState().pages.byId.welcome.content
    }
    render(){
        return (
            <SectionPage className="welcome-page"
                         onBGClick={this.props.onClose}
                         isPopup={true}
                         headerImageUrl={headerImg}
                         html={marked(this.getContent())} />
        )
    }
}
